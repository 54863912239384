<template>
  <router-link
    :to="
      isin === null || isin === undefined ? '#' : { name: productRouteName, params: { isin: isin } }
    "
  >
    <PCard class="product-card" :id="id" :key="id">
      <template #header>
        <div class="p-d-flex">
          <Tag
            class="product-type p-mr-2 p-tag-inverted p-text-uppercase"
            :value="getProductType(internalProductTypeId)"
            v-if="internalProductTypeId"
          />
          <Skeleton v-if="loading" width="2.75em" height="1.75em" class="p-mr-2" />
          <Tag class="p-tag-outlined p-text-uppercase" :value="currencyIso" v-if="currencyIso" />
          <Skeleton v-if="loading" width="2.75em" height="1.75em" />
        </div>
        <div>
          <icon
            v-if="hasTermsheet"
            data="@icon/file-pdf.svg"
            :fill="false"
            color="var(--primary-color)"
            width="1.5rem"
            height="1.75rem"
            class="stroke-icon"
          />
          <KVCard grid="50%" inline fullWidth>
            <Status
              :value="productState?.id"
              :label="productState?.externalName"
              v-if="loading || (!loading && productState)"
            />
          </KVCard>
        </div>
      </template>
      <template #title>
        <h6 class="title">
          {{ longName }}
          <Skeleton width="100%" v-if="loading" height="1em" class="p-mb-1" />
        </h6>
        <div class="p-d-flex p-justify-between p-flex-wrap">
          <KVCard label="ISIN" inline :loading="loading" v-if="(!loading && isin) || loading">
            {{ isin }}
          </KVCard>
          <KVCard label="Valor" inline :loading="loading" v-if="(!loading && valor) || loading">
            {{ valor }}
          </KVCard>
        </div>
      </template>
      <template #content v-if="showIssuer">
        <KVCard inline fullWidth v-if="showWorkflows">
          <WorkflowsInProgress :workflows="currentWorkflows" class="p-text-truncate" />
        </KVCard>
        <KVCard
          label="Issuer"
          :loading="loading"
          v-if="(!loading && issuerName) || loading"
          class="p-mb-2"
          inline
          fullWidth
          contentAlign="right"
        >
          <strong>{{ issuerName }}</strong>
        </KVCard>
        <KVCard
          label="Issue date"
          :loading="loading"
          v-if="(!loading && paymentDate) || loading"
          class="p-mb-2"
          inline
          fullWidth
          contentAlign="right"
        >
          <Date v-if="paymentDate">{{ paymentDate }}</Date>
        </KVCard>
        <KVCard
          label="Termination date"
          :loading="loading"
          v-if="terminatedAt"
          class="p-mb-2"
          inline
          fullWidth
          contentAlign="right"
        >
          <Date>{{ terminatedAt }}</Date>
        </KVCard>
        <KVCard
          label="Termination Price"
          :loading="loading"
          v-if="terminationPrice"
          class="p-mb-2"
          inline
          fullWidth
          contentAlign="right"
        >
          <CurrencyValue :currency="currencyIso">
            {{ terminationPrice }}
          </CurrencyValue>
        </KVCard>
      </template>
      <template #footer>
        <KVCard
          v-if="latestProductValuation?.price"
          label="Valuation"
          :loading="loading"
          class="p-mb-2"
          inline
          fullWidth
        >
          <CurrencyValue
            :currency="currencyIso"
            size="large"
            v-if="Number.isFinite(latestProductValuation?.price) && currencyIso"
          >
            {{ latestProductValuation?.price }}
          </CurrencyValue>
        </KVCard>
        <KVCard
          v-if="grossAssets"
          label="Gross Assets"
          :loading="loading"
          class="p-mb-2"
          inline
          fullWidth
        >
          <CurrencyValue :currency="currencyIso">
            {{ grossAssets }}
          </CurrencyValue>
        </KVCard>
        <Date
          v-if="latestProductValuation?.priceDateTime"
          class="p-flex-1 p-text-right"
          dateColor="var(--key-value-card-title-color)"
          size="small"
        >
          {{ latestProductValuation?.priceDateTime }}
        </Date>
        <template
          v-if="
            isVisibleOnDashboard && !productRouteName.includes('Dashboard') && getShowcasedProduct
          "
        >
          <Divider v-if="latestProductValuation || grossAssets"></Divider>
          <div class="p-d-flex p-align-center dashboard-product">
            <icon data="@icon/loudspeaker.svg" width="1.5em" height="1.5em" class="p-mr-2" />
            <span>This product is promoted on client dashboards</span>
          </div>
        </template>
      </template>
    </PCard>
  </router-link>
</template>

<script>
import PCard from "primevue/card";
import Skeleton from "primevue/skeleton";
import Tag from "primevue/tag";
import KVCard from "@/components/elements/KeyValueCard";
import Date from "@/components/elements/Date";
import Status from "@/components/elements/Status";
import CurrencyValue from "@/components/elements/CurrencyValue";
import WorkflowsInProgress from "@/components/elements/WorkflowsInProgress";
import Divider from "primevue/divider";
import productMixins from "@/mixins/product";
import { ValuationTypeEnum } from "@/components/product/Types";

export default {
  name: "ProductCard",
  mixins: [productMixins],
  components: {
    PCard,
    KVCard,
    Date,
    Status,
    WorkflowsInProgress,
    Skeleton,
    Tag,
    CurrencyValue,
    Divider,
  },
  props: {
    id: Number,
    productRouteName: { type: String, default: "Product" },
    internalProductTypeId: Number,
    longName: String,
    latestProductValuation: Object,
    terminatedAt: String,
    terminationPrice: Number,
    currencyIso: String,
    isin: String,
    valor: String,
    currentWorkflows: Array,
    paymentDate: String,
    date: [Date, String],
    issuerName: String,
    hasTermsheet: Boolean,
    productState: Object,
    isVisibleOnDashboard: Boolean,
    showWorkflows: {
      type: Boolean,
      default: true,
    },
    showIssuer: {
      type: Boolean,
      default: true,
    },
    showGrossAssets: {
      type: Boolean,
      default: true,
    },
    latestOutstandingCertificate: Object,
    valuationTypeId: Number,
  },
  computed: {
    loading() {
      return !this.id;
    },
    grossAssets() {
      return (
        this.showGrossAssets &&
        (this.latestOutstandingCertificate?.value * this.latestProductValuation?.price) /
          (this.valuationTypeId === ValuationTypeEnum.Percentage ? 100 : 1)
      );
    },
    getShowcasedProduct() {
      return (
        this.productState?.externalName === "Issued" ||
        this.productState?.externalName === "Open for subscriptions"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.product-card {
  height: 100%;
  transition: all 0.3s ease;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: var(--card-padding);
  border: 1px solid var(--card-border-bg);

  &.overdue {
    border-color: var(--traffic-light-red);
  }

  :deep() {
    & > .p-card-header {
      display: flex;
      justify-content: space-between;
      padding-bottom: var(--card-padding);
      flex-wrap: wrap;
      gap: 0.3em;

      .product-type {
        background: var(--card-tag-inverted-bg);
        border: 1px solid transparent;
      }
    }

    & > .p-card-body {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      padding: 0;

      & > .p-card-caption {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        & > .p-card-title {
          flex: 1 0 auto;
          padding-bottom: var(--card-padding);
          border-bottom: 1px solid var(--card-border-color);
          margin-bottom: 0;

          .title {
            flex: 1;
            font-size: 1.3333334rem;
            line-height: 1.375;
            margin: 0 0 1rem;
          }
        }
      }

      & > .p-card-content {
        border-bottom: 1px solid var(--card-border-color);

        &:empty {
          display: none;
        }
      }
    }

    .workflows-in-progress {
      margin-bottom: 1rem;
    }
  }

  &:hover,
  &:focus {
    background: var(--card-hover-bg);
    box-shadow: 2px 2px 10px 0px var(--card-hover-shadow);
  }

  .valuation {
    :deep() {
      .p-card-title {
        color: var(--primary-color);
        font-size: 0.85rem;
      }

      .currency-value {
        margin: 0.5rem 0;
        font-weight: 600;

        .currency {
          align-self: baseline;
        }
      }
    }
  }

  .dashboard-product {
    color: var(--secondary-color-text-neutral);
  }
}
</style>
